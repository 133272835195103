class ZIDXSavedSearch implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxSavedSearchContainer";
    }
    getMatchURL(){
        return "/saved-search/";
    }
    render(){
        // console.log("saved search");
    }
}